import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import Head from '../components/head'
import BtnLink from '../components/btnLink/btnLink'

import styles from '../styles/publications.module.scss'

export default ({ data }) => {
  const { allFile, allMarkdownRemark } = data
  return (
    <Layout>
      <Head title="Publications" />
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <h1>Publications</h1>
        </div>
        <div className={styles.content}>
          {allMarkdownRemark.edges.map(({ node }) => {
            const frontmatter = node.frontmatter
            let img = null

            // Find the related image (if there is one):
            if (frontmatter.cover) {
              const imgObj = allFile.edges.find(({ node: n }) => {
                return n.base === frontmatter.cover.split('/').slice(-1)[0]
              })
              img = imgObj.node
            } else {
              img = null
            }

            return (
              <div key={frontmatter.title} className={styles.publication_item}>
                <div className={styles.publication_thumb}>
                  {img && (
                    <Img
                      fluid={img.childImageSharp.fluid}
                      alt={frontmatter.title}
                      title={frontmatter.title}
                      style={{ margin: '0 auto' }}
                    />
                  )}
                </div>
                <div
                  className={
                    img
                      ? styles.publication_info
                      : styles.publication_info__nocover
                  }
                >
                  <h3>{frontmatter.title}</h3>
                  <BtnLink
                    href={`/publications${node.fields.slug}`}
                    text="More Info"
                    inversed={true}
                  />
                  <p>{frontmatter.author}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query {
    allFile(
      sort: { fields: name, order: ASC }
      filter: { extension: { regex: "/(jpg)|(jpeg)|(png)|(gif)|(bmp)/" } }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
          base
          relativePath
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___published }
      filter: { fileAbsolutePath: { regex: "/(publications)/" } }
    ) {
      edges {
        node {
          frontmatter {
            author
            buylink_link
            buylink_text
            isbn
            published
            title
            cover
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
